var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-content" },
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", [
            _c(
              "div",
              { staticClass: "tabs" },
              [
                _vm._l(_vm.tabItems, function(tab, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      ref: "tab",
                      refInFor: true,
                      staticClass: "tab__item",
                      on: {
                        click: function($event) {
                          return _vm.switchTab(index)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(tab.name) + " ")]
                  )
                }),
                _c("div", {
                  staticClass: "slider",
                  style:
                    "transform:translateX(" +
                    _vm.activetab * _vm.tabwidth +
                    "px)"
                })
              ],
              2
            ),
            _c(
              "div",
              { ref: "tcon", staticClass: "tab-item" },
              _vm._l(_vm.tabItems, function(tab, index) {
                return _c(
                  "transition",
                  { key: index, attrs: { name: _vm.transition } },
                  [
                    index === _vm.activetab
                      ? _c("div", { staticClass: "tab__pane" }, [
                          _vm.isTable
                            ? _c(
                                "div",
                                { staticClass: "table-wrapper" },
                                [
                                  _c("Table", {
                                    attrs: {
                                      "table-headers": tab.tableHeaders,
                                      "table-data-rows": tab.tableDataRows,
                                      isVerticalTable: tab.isVerticalTable
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(" " + _vm._s(tab.description) + " ")
                              ])
                        ])
                      : _vm._e()
                  ]
                )
              }),
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
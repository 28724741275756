<template>
  <div class="tab-content">
    <Loader :loading="isLoader" v-if="isLoader" />
    <div v-else>
      <div class="tabs">
        <div
          class="tab__item"
          v-for="(tab, index) in tabItems"
          @click="switchTab(index)"
          :key="index"
          ref="tab"
        >
          {{ tab.name }}
        </div>
        <div
          class="slider"
          :style="'transform:translateX(' + activetab * tabwidth + 'px)'"
        ></div>
      </div>

      <div ref="tcon" class="tab-item">
        <transition
          :name="transition"
          v-for="(tab, index) in tabItems"
          :key="index"
        >
          <div class="tab__pane" v-if="index === activetab">
            <div class="table-wrapper" v-if="isTable">
              <Table
                :table-headers="tab.tableHeaders"
                :table-data-rows="tab.tableDataRows"
                :isVerticalTable="tab.isVerticalTable"
              />
            </div>
            <div v-else>
              {{ tab.description }}
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "./Loader"),
    Table: () => import(/* webpackPrefetch: true */ "./Table"),
  },
  props: {
    tabItems: {
      type: Array,
    },
    isTable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoader: true,
    transition: "slide-next",
    activetab: 0,
    tabwidth: 180,
    touch: { sx: null, sy: null, st: null, ex: null, ey: null, et: null },
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
    }, 500);
  },
  methods: {
    switchTab(n) {
      let scroll, scond;
      if (this.activetab > n) {
        this.transition = "slide-prev";
        scroll = n - 1;
        if (scond)
          this.$refs.tab[scroll].scrollIntoView({ behavior: "smooth" });
      } else if (this.activetab < n) {
        this.transition = "slide-next";
        scroll = n + 1;
      }
      scond = scroll >= 0 && scroll < this.tabItems.length;
      if (scond) this.$refs.tab[scroll].scrollIntoView({ behavior: "smooth" });
      // eslint-disable-next-line
      this.$nextTick((_) => {
        this.activetab = n;
      });
    },
    start(e) {
      this.setTouchpos(e, "start");
    },
    move(e) {
      this.setTouchpos(e, "move");
    },
    end(e) {
      this.setTouchpos(e, "end");
      let dx = this.touch.sx - this.touch.ex,
        dy = this.touch.sy - this.touch.ey,
        dt = this.touch.et - this.touch.st,
        dir = Math.sign(dx),
        ntab = this.activetab + dir,
        vmove = Math.abs(dx) / Math.abs(dy) < Math.sqrt(4);

      ntab = ntab >= 0 && ntab < this.tabItems.length ? ntab : null;
      if (Math.abs(dx) > 10 && ntab !== null && !vmove && dt < 300)
        this.switchTab(ntab);
    },
    setTouchpos(e, event) {
      let ev = e.changedTouches ? e.changedTouches[0] : e;
      if (event === "start") {
        this.touch.sx = ev.clientX;
        this.touch.sy = ev.clientY;
        this.touch.st = Date.now();
      } else {
        this.touch.ex = ev.clientX;
        this.touch.ey = ev.clientY;
        this.touch.et = Date.now();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
}
.tab-content {
  margin: 0px 10px 10px 10px;
  .tabs {
    display: flex;
    position: relative;
    background: #1565c0;
    color: white;
    height: 38px;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
      0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12);
    overflow-x: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    .tab__item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 180px;
      cursor: pointer;
      font-size: 1.1rem;
    }

    .slider {
      position: absolute;
      bottom: 0px;
      height: 2px;
      width: 180px;
      background: white;
      transition: 0.5s ease;
    }
  }
  .tabs::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  .tab-item {
    position: relative;
    min-height: 100%;
    width: 100%;
    touch-action: none;
    .tab__pane {
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      .table-container {
        margin-top: 20px;
        max-height: 390px;
        overflow-y: scroll;
        
      }
    }
  }
}
</style>
